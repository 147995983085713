const modal = new bootstrap.Modal(document.getElementById('modal'), {
  backdrop: 'static',
});

function readCookie(name) {
  // Reads a cookie by name and returns its value
  let nameEQ = encodeURIComponent(name) + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
  }
  return null;
}

function setCookie(name, value, days) {
  // Sets a cookie with the specified name, value, and expiration days
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  }
  const domain =
    location.hostname == 'localhost'
      ? ''
      : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
  const security = location.hostname == 'localhost' ? '' : ';SameSite=None; Secure';
  document.cookie = name + '=' + value + expires + ';path=/' + domain + security;
}

function getParameterByName(name, url) {
  // Retrieves a query parameter value from a URL
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function updateQueryStringParameter(uri, key, value) {
  // Updates or adds a query string parameter in a given URI
  let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  let separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

const modalPage = readCookie('modalPage');
const modalShown = readCookie('modalShown');
const groupId = readCookie('_up4gid');

function ModalInit(showZipCodeCheck, listOfClubsToCheck, restrictClubs, memberOnlyClubs, bypassMember, bypassMemberNon) {
  // If the current page is different from the modal page cookie and if the modal has not been shown before, show the modal
  if (modalPage != window.location.href.split('?')[0] && modalShown != true) {
    // Declares a variable to track the validity of the zip code
    let validZip = 0;
    // Declares a variable to store the error message element
    const errorMsgEl = document.querySelector('#errormsg');
    // Declares variables to store the zip code and ID input elements
    const getZip = document.querySelector('#member_login #get_zip');
    const getId = document.querySelector('#member_login #get_id');

    modal.show();
    //using club.js for clubs and clubNums variable, checks if zip code check is enabled
    if (showZipCodeCheck) {
      // If the zip code check is enabled, set up event listeners for zip code submission
      const submitZip = document.querySelector('#member_login #submitZip');
      submitZip.addEventListener('click', function () {
        // Declares variables to track the restricted, member-only, and bypass membership modal flags
        let varRestrictedClub;
        let varMemberOnlyClub;
        let varBypassMember;
        let varBypassMemberNon;

        //var listOfClubsToCheck = ['AAA Automobile Club of South California', 'AAA Mountain West Group'];
        const zipcode = document.querySelector('#member_login #member_zipcode').value;
        const clubByZipCode = clubs.find((club) => club.ZipCodes.includes(zipcode));

        // Checks if the entered zip code matches any club's zip codes
        if (clubByZipCode) {
          // console.log('Valid Zip Code');
          // console.log('ClubName: ' + clubByZipCode.ClubName);
          // console.log('GroupId: ' + clubByZipCode.groupId);
          // console.log('Zip Code: ' + zipcode);
          setCookie('zipcode', zipcode);
          setCookie('ClubName', clubByZipCode.ClubName);
          setCookie('_up4gid', clubByZipCode.groupId);
          validZip = 1;
          // If the zip code matches, check the club restrictions
          if (restrictClubs) {
            // Checks if the club is restricted
            if (restrictClubs.includes(clubByZipCode.ClubName)) {
              // Sets the restricted club flag if the club is in the list
              varRestrictedClub = true;
              //console.log('varRestrictedClubName: ' + clubByZipCode.ClubName);
            }
          }
          if (memberOnlyClubs) {
            // Checks if the club is for members only
            if (memberOnlyClubs.includes(clubByZipCode.ClubName)) {
              // Sets the member-only club flag if the club is in the list
              varMemberOnlyClub = true;
              //console.log('varMemberOnlyClub: ' + clubs[i].ClubName);
            }
          }
          if (bypassMember) {
            // Checks if the club allows bypassing membership
            if (bypassMember.includes(clubByZipCode.ClubName)) {
              // Sets the bypass member flag if the club is in the list
              varBypassMember = true;
              // console.log('varBypassMember: ' + clubByZipCode.ClubName);
            }
            //console.log('in limits');
          }
          if (bypassMemberNon) {
            // Checks if the club allows bypassing membership
            if (bypassMemberNon.includes(clubByZipCode.ClubName)) {
              // Sets the bypass member flag if the club is in the list
              varBypassMemberNon = true;
              // console.log('bypassMemberNon: ' + clubByZipCode.ClubName);
            }
            //console.log('in limits');
          }
          if (
            listOfClubsToCheck.includes(clubByZipCode.ClubName) &&
            !varRestrictedClub &&
            !varMemberOnlyClub &&
            !varBypassMember &&
            !varBypassMemberNon
          ) {
            // Handles the case where the club is not restricted, not member-only, and not bypassing membership
            getZip.style.display = 'none';
            getId.style.display = 'block';
          } else if (listOfClubsToCheck.includes('AllClubs')) {
            // Handles the case where all clubs are allowed
            getZip.style.display = 'none';
            getId.style.display = 'block';
          } else if (listOfClubsToCheck.includes(clubByZipCode.ClubName) && varMemberOnlyClub) {
            // Handles the case where the club is Member Only
            // Handles member-only club logic
            const member = document.querySelector('#member_login #get_id .member');
            const notMember = document.querySelector('#member_login #get_id .notmember');

            getZip.style.display = 'none';
            getId.style.display = 'block';
            member.classList.add('memberonly');
            notMember.style.display = 'none';
            // console.log('varMemberOnlyClub');
          } else if (listOfClubsToCheck.includes(clubByZipCode.ClubName) && varBypassMember) {
            // Handles when bypassing membership screen logic
            bypassMembership();
            // console.log('varBypassMember');
          } else if (listOfClubsToCheck.includes(clubByZipCode.ClubName) && varBypassMemberNon) {
            // Handles when bypassing membership screen logic
            bypassMembershipNon();
            // console.log('varBypassMemberNon');
          } else if (listOfClubsToCheck.includes(clubByZipCode.ClubName) && varRestrictedClub) {
            // Handles when the club is restricted
            validZip = 2;
            // console.log('varRestrictedClub');
            // console.log(validZip);
          } else {
            validZip = 3;
            // console.log(validZip);
          }
        }

        // Displays an error message based on the validity of the zip code
        if (validZip === 0) {
          errorMsgEl.classList.contains('errormsg')
            ? (errorMsgEl.innerHTML = '')
            : (errorMsgEl.innerHTML = `<p class='errormsg'>Please check the Zip Code you entered.</p>`);
        } else if (validZip === 2) {
          errorMsgEl.classList.contains('errormsg')
            ? (errorMsgEl.innerHTML = '')
            : (errorMsgEl.innerHTML = `<p class='errormsg'>This course is not yet available in your area.</p>`);
        } else if (validZip === 3) {
          errorMsgEl.classList.contains('errormsg')
            ? (errorMsgEl.innerHTML = '')
            : (errorMsgEl.innerHTML = `<p class='errormsg'>You have entered a zip code for a different state. Please check your zip code and try again.</p>`);
        }
      });
    } else {
      // If zip code check is not enabled, show the ID input directly
      getZip.style.display = 'none';
      getId.style.display = 'block';
    }
    // Event listener for member login submission
    document.querySelector('#member_login #submitMember').addEventListener('click', function () {
      // Validates member number and sets cookies accordingly
      const memberNum = document.querySelector('#member_login #member_number').value;
      // Check if the member number is a 16-digit number and only contains numbers
      if (memberNum.match(/^[0-9]+$/) != null && memberNum.length == '16') {
        // Extract the first 6 digits of the member number
        const clubNum = memberNum.substring(0, 6).trim();
        // console.log('Club Number: ' + clubNum);
        // Check if the listOfClubsToCheck is set to just one Club and that zip code check is not enabled
        if (listOfClubsToCheck && listOfClubsToCheck.length === 1 && !showZipCodeCheck) {
          // Find the club in the clubs array
          const club = clubs.find((club) => club.ClubName === listOfClubsToCheck[0]);
          // If the club is found, set the Group ID and TP cookies and the ClubName cookie
          if (club) {
            // console.log('ClubName: ' + club.ClubName);
            // console.log('Group ID: ' + club.groupId);
            // console.log('Continuing as a member');
            setCookie('ClubName', club.ClubName);
            setCookie('_up4gid', club.groupId);
            setCookie('_up4tp', 'B');
          }
        }
        // Check if the club number is in clubNums Array or is a number between 620000 and 629999
        if (clubNums.indexOf(clubNum) >= 0 || (Number(clubNum) >= 620000 && Number(clubNum) <= 629999)) {
          modal.hide();
          setCookie('member', true);
          setCookie('idnumber', memberNum);
          setCookie('_up4tp', 'B');
          setCookie('modalPage', window.location.href.split('?')[0]);
          setCookie('modalShown', true);
          refreshPageWithGidQuery();
        } else {
          checkMemberError();
        }
      } else {
        checkMemberError();
      }
    });
    // Event listener for non-member submission
    document.querySelector('#member_login #notMember').addEventListener('click', function () {
      // Handles logic for non-member login
      // Check if the listOfClubsToCheck is set to just one Club and that zip code check is not enabled
      if (listOfClubsToCheck && listOfClubsToCheck.length === 1 && !showZipCodeCheck) {
        // Find the club in the clubs array
        const club = clubs.find((club) => club.ClubName === listOfClubsToCheck[0]);
        // If the club is found, set the Group ID cookie
        if (club) {
          // console.log('ClubName: ' + club.ClubName);
          // console.log('Group ID: ' + club.groupId);
          // console.log('Continuing as a guest');
          setCookie('ClubName', club.ClubName);
          setCookie('_up4gid', club.groupId);
        }
      }
      modal.hide();
      if (readCookie('_up4tp') && readCookie('_up4tp').toLowerCase() === 'b') {
        setCookie('_up4tp', 'A');
      }
      setCookie('member', false);
      setRegUrlNonMember();
    });
  }
}

// Displays an error message if the member number is invalid
function checkMemberError() {
  const memberErrorEl = document.querySelector('#member_login #member-search-error');
  const memberErrorMsg = `<p class='errormsg'>Please check the number you entered.</p>`;
  memberErrorEl.classList.contains('errormsg') ? (memberErrorEl.innerHTML = '') : (memberErrorEl.innerHTML = memberErrorMsg);
}

// Refreshes the page with the Group ID query string
function refreshPageWithGidQuery() {
  window.location.href = updateQueryStringParameter(window.location.href, 'gid', readCookie('_up4gid'));
}

// hack to bypass membership modal for AAA Automobile Club of South California
function bypassMembership() {
  setCookie('_up4tp', 'B');
  // console.log('Member Pricing Set');
  modal.hide();
  setCookie('modalPage', window.location.href.split('?')[0]);
  setCookie('modalShown', true);
  refreshPageWithGidQuery();
}

// Bpass membership modal and set A pricing
function bypassMembershipNon() {
  setCookie('_up4tp', 'A');
  // console.log('Non Member Pricing Set');
  modal.hide();
  setCookie('modalPage', window.location.href.split('?')[0]);
  setCookie('modalShown', true);
  refreshPageWithGidQuery();
}

// Sets the registration URL for non-member purchases
function setRegUrlNonMember() {
  const links = document.getElementsByTagName('a');
  for (let i = 0; i < links.length; i++) {
    const groupId = readCookie('_up4gid');
    let href = links[i].getAttribute('href');
    if (href.indexOf('cart.aaadriverprogram.com') != -1) {
      href = updateQueryStringParameter(href, 'gid', groupId);
      links[i].setAttribute('href', href);
    }
  }
  switchPID();
  setCookie('modalPage', window.location.href.split('?')[0]);
  const date = new Date();
  date.setTime(date.getTime() + 300 * 1000); //5 minutes
  setCookie('modalShown', true, date);
  refreshPageWithGidQuery();
}

// Updates the PIDs for specific clubs
function switchPID() {
  const links = document.getElementsByTagName('a');
  for (let i = 0; i < links.length; i++) {
    let href = links[i].getAttribute('href');
    const pid = getParameterByName('productId', href);
    const club = readCookie('ClubName');
    // SWITCH FOR VIRGINIA CLUB - "va_dip" (1513) and "va_dip_Tidewater" (1725)
    if (href.indexOf('cart.aaadriverprogram.com') != -1) {
      if (pid === '652cd8ee-66c9-45cd-2a93-08dcd6510d46' && club === 'AAA Tidewater Virginia') {
        href = updateQueryStringParameter(href, 'productId', 'f5932dfd-b63f-42c6-2a94-08dcd6510d46');
        links[i].setAttribute('href', href);
        links[i].setAttribute('data-pid', 'f5932dfd-b63f-42c6-2a94-08dcd6510d46');
      }
    }
    // SWITCH FOR CALIFORNIA CLUB - South and North California "ca_h2d_South" (3572) and "ca_h2d_North" (3586)
    // This currently is not being used: Only pages that use this would be /california, but the h2d productType redirects to another page
    if (href.indexOf('cart.aaadriverprogram.com') != -1) {
      if (pid === '06ed4343-5e83-41af-2a19-08dcd6510d46' && club === 'AAA Mountain West Group') {
        href = updateQueryStringParameter(href, 'productId', 'e55e1efd-a89c-44f6-2a27-08dcd6510d46');
        links[i].setAttribute('href', href);
        links[i].setAttribute('data-pid', 'e55e1efd-a89c-44f6-2a27-08dcd6510d46');
      }
    }
    // SWITCH FOR CALIFORNIA ROADWISE - "ca_rw_north 8 hour" (1480) and "ca_rw_north 4 hour" (1479) for ca_rw_south 8 hour (1480) and ca_rw_south 4 hour (1479)
    if (href.indexOf('cart.aaadriverprogram.com') != -1) {
      if (pid === 'e83ac366-e997-4197-0098-08dcd7dc8a16' && club === 'AAA Automobile Club of South California') {
        href = updateQueryStringParameter(href, 'productId', 'eaf9cebb-786c-4568-2a26-08dcd6510d46');
        links[i].setAttribute('href', href);
        links[i].setAttribute('data-pid', 'eaf9cebb-786c-4568-2a26-08dcd6510d46');
      } else if (pid === '34d20e79-28ca-43a9-0097-08dcd7dc8a16' && club === 'AAA Automobile Club of South California') {
        href = updateQueryStringParameter(href, 'productId', '04cee802-0023-4d90-2a28-08dcd6510d46');
        links[i].setAttribute('href', href);
        links[i].setAttribute('data-pid', '04cee802-0023-4d90-2a28-08dcd6510d46');
      }
    }
  }
}

switchPID();
